import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
import resmed1 from "./resmed-01.gif";
export const query = graphql`
  query {
    resmed2: file(relativePath: { eq: "ResMed/resmed-02.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed3: file(relativePath: { eq: "ResMed/resmed-03.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed4: file(relativePath: { eq: "ResMed/resmed-04.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed5: file(relativePath: { eq: "ResMed/resmed-05.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed6: file(relativePath: { eq: "ResMed/resmed-06.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed7: file(relativePath: { eq: "ResMed/resmed-07.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed8: file(relativePath: { eq: "ResMed/resmed-08.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed9: file(relativePath: { eq: "ResMed/resmed-09.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    resmed10: file(relativePath: { eq: "ResMed/resmed-10.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.resmed2.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.resmed4.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.resmed3.childImageSharp.fluid} mdxType="ImageContainer" />
      <div className="small right"><img src={resmed1} alt="Prototype Animation" /></div>
      <p className="small space-me right blog-text">This prototype illustrates the scrolling animation concept for the homepage.</p>
      <ImageContainer classes="small right" fluid={props.data.resmed5.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.resmed6.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.resmed7.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.resmed8.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.resmed9.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">For the product page I went with an illustrative and typographic approach. The idea here was to educate the user on sleep apnea and also prove that ResMed is a leader in their respected field.</p>
      <ImageContainer classes="large" fluid={props.data.resmed10.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small right blog-text">Please contact me if you'd like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      